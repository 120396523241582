'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Locale } from '../../../../i18n-config';
import { useTranslation } from '@/i18n/client';

const Section15 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  return (
    <div className="sec-15 d-none d-lg-block tw-mt-16">
      <Container>
        <Row className="pb-0 mb-0">
          <Col>
            <Image
              className="tw-w-full tw-h-[300px] tw-object-contain"
              alt="Old lady"
              src={'/images/whytimnak1.webp'}
              width={0}
              height={0}
              sizes="100vw"
            />
          </Col>
          <Col>
            <div className="text-center">
              <h3 className="mt-5">{t('Why travel with TIMNAK? ')}</h3>

              <h4>{t('Fabulous Experience ')}</h4>

              <p>{t('Meeting locals and Iranian families . . .  ')}</p>

              <Link href={`/${lang}/tailor-made-iran-tours`} title="customize your tour">
                <button className="btn btn-success" aria-label="customize your tour">
                  {t('Explore More ')}
                </button>
              </Link>
            </div>
          </Col>
          <Col>
            <Image
              alt="young lady"
              src={'/images/whytimnak2.webp'}
              width={0}
              height={0}
              className="tw-w-[300px] tw-h-[300px] tw-object-contain"
              sizes="100vw"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Section15;
