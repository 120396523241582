'use client';

import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import parser from 'html-react-parser';
import Contexts from '@/contexts';
import { YouTubeEmbed } from '@next/third-parties/google';

const Section9 = () => {
  const generalData = useContext(Contexts.sharedDataContext);

  const renderId = (url: string): string => {
    const splitUrl = url.split('/');

    const videoId = splitUrl[splitUrl.length - 1].split('?')[0];

    return videoId;
  };

  if (generalData && generalData.video_url)
    return (
      <div className="sec-9 tw-my-5">
        <Container>
          <YouTubeEmbed
            videoid={renderId(generalData.video_url.split('src=')[1].split(/[ >]/)[0])}
            params="controls=0"
          />
        </Container>
      </div>
    );
};

export default Section9;
