'use client';

import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../../i18n-config';
import { useParams } from 'next/navigation';
import { Card, Col, Container, Row } from 'react-bootstrap';

const Section8 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  return (
    <div className="sec-8">
      <Container className="mt-5">
        <hr className="hr-text" data-content={t('Popular Travel Styles')} />

        <Row className="tw-justify-center">
          <Col lg={8} md={10} sm={11} xs={12}>
            <Row className="g-md-2 g-3">
              <Col xs={12}>
                <Link aria-label={t('Photography Tours')} className="tw-block" href={`${lang}/tours?style=Photography`}>
                  <Card className="tw-relative">
                    <Image
                      width={'0'}
                      height={'0'}
                      className="tw-w-full tw-h-full"
                      sizes="100vw"
                      src="/images/nomads_and_villages_photography_tours_to_IRAN2.webp"
                      alt="Photography Tours"
                    />
                    <Card.Body>
                      <Card.Title as={'h2'} className="textShadow">
                        {t('Photography Tours')}
                      </Card.Title>
                      <Card.Text>
                        {t(
                          'Iran Journey Tours are specialized adventures for photographers of all abilities and equipment levels.'
                        )}
                        {t('From Wildlife photo expeditions, architecture and monuments photo tours, and more.')}
                        {t(
                          'Each Iran journey is led by a highly trained naturalist photographer leader adept at offering personalized guidance.'
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>

              <Col md={7} xs={12} className="tw-flex tw-flex-col">
                <Row className="g-md-2 g-3 tw-flex-1">
                  <Col xs={12} className="tw-flex">
                    <Link aria-label={t('key2')} className="tw-block" href={`${lang}/tours?style=Group_Tours`}>
                      <Card className="tw-flex-1 tw-relative">
                        <Image
                          width={'0'}
                          height={'0'}
                          className="tw-w-full tw-h-full"
                          sizes="100vw"
                          src="/images/grouptours_to_iran-_group_tours_iran_journey2.webp"
                          alt="Group Tours"
                        />
                        <Card.Body>
                          <Card.Title as={'h2'} className="textShadow">
                            {t('key2')}
                          </Card.Title>
                          <Card.Text>
                            {t(
                              'For those once-in-a-lifetime destinations, leave your itinerary in the hand of the experts. Some people feel more safety in groups, this feeling became twice for a destination like IRAN. Check out our group tours with classic visits, mountaineering, and photography purposes.'
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                  <Col xs={12} className="tw-flex">
                    <Link
                      aria-label={t('Nature Adventures & Hiking')}
                      className="tw-block"
                      href={`${lang}/tours?style=NatureAdventure`}
                    >
                      <Card className="tw-flex-1 tw-relative">
                        <Image
                          width={'0'}
                          height={'0'}
                          className="tw-w-full tw-h-full"
                          sizes="100vw"
                          src="/images/Nature_adventure_tours_to_iran-_iran_hiking_tours_iran_damavand_tours2.jpg"
                          alt="Nature Adventures & Hiking"
                        />
                        <Card.Body>
                          <Card.Title as={'h2'} className="textShadow">
                            {t('Nature Adventures & Hiking')}
                          </Card.Title>
                          <Card.Text>
                            {t(
                              'We are a leading and innovative nature adventure tour operator in IRAN. Expedition journey to Gandom Beryan volcano craters, Giant sand dunes of Rig-e Yalan, or Damavand trekking program with visiting a stratovolcano, and a breathtaking ice waterfall, tasting the delicious local foods and relaxing in natural hot springs.'
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                  <Col xs={12} className="tw-flex">
                    <Link
                      aria-label={t('Private Tours')}
                      className="tw-block"
                      href={`${lang}/tours?style=Seniors-Classic`}
                    >
                      <Card className="tw-flex-1 tw-relative">
                        <Image
                          width={'0'}
                          height={'0'}
                          className="tw-w-full tw-h-full"
                          sizes="100vw"
                          src="/images/private_tours_to_iran_-_iran_senior_travels_-_iran_family_tours_iran_private_trips2.webp"
                          alt="Private Tours"
                        />
                        <Card.Body>
                          <Card.Title as={'h2'} className="textShadow">
                            {t('Private Tours')}
                          </Card.Title>
                          <Card.Text>
                            {t(
                              'Our private Iran tours are 100% tailor-made itineraries that are recommended for seniors, small groups, family journeys. We consider your personal preferences and favorite sightseeing and activities'
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                </Row>
              </Col>

              <Col md={5} xs={12} className="tw-flex">
                <Row className="g-md-2 g-3 tw-flex-1">
                  <Col xs={12} className="tw-flex">
                    <Link
                      aria-label={t('Rail Journeys')}
                      className="tw-block"
                      href={`${lang}/tours?style=RailJourneys`}
                    >
                      <Card className="tw-flex-1 tw-relative">
                        <Image
                          width={'0'}
                          height={'0'}
                          className="tw-w-full tw-h-full"
                          sizes="100vw"
                          src="/images/Train_iran_journey-Iran_rail_tours-rail_tours_to_iran2.webp"
                          alt="sample72"
                        />
                        <Card.Body>
                          <Card.Title as={'h2'} className="textShadow">
                            {t('Rail Journeys')}
                          </Card.Title>
                          <Card.Text>
                            {t(
                              "There are many reasons for choosing to travel with Iran Journey. Imagine boarding a cultural train, traveling through beautiful landscapes, mountains, historic and cultural sightseeing in all of the glory. A rail Journey in Iran doesn't just take you around the country, you travel through the Persian culture and landscape."
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                  <Col xs={12} className="tw-flex">
                    <Link
                      aria-label={t("18 to 30's")}
                      className="tw-block"
                      href={`${lang}/tours?style=Seniors-Classic`}
                    >
                      <Card className="tw-flex-1 tw-relative">
                        <Image
                          width={'0'}
                          height={'0'}
                          className="tw-w-full tw-h-full"
                          sizes="100vw"
                          src="/images/18to30s_iran_tours_-_iran_journeys_-_iran_travels2.webp"
                          alt="sample72"
                        />
                        <Card.Body>
                          <Card.Title as={'h2'} className="textShadow">
                            {t("18 to 30's")}
                          </Card.Title>
                          <Card.Text>
                            {t(
                              "Explore Persia with like-minded young adults designed for travelers from 18 to '30s. We offer the best guided and expert planned vacation and holiday package"
                            )}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Section8;
