import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section1.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section10.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section11.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section12.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section13.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section14.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section15.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section2.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section3.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section4.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section5.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section6.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section7.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section8.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/Section9.tsx")