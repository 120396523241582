'use client';

import React, { useEffect, useState } from 'react';
import { Button, Carousel } from 'react-bootstrap';
import moment from 'moment';
import { useQuery } from 'react-query';
import Image from 'next/image';
import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import { Locale } from '../../../../i18n-config';
import Loading from '@/components/shared/Loading';
import ApiFailed from '@/components/shared/ApiFailed';

const PageCarousel = ({ slides, restData, i18n }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const titleMapper = {
    en: 'title_latin',
    de: 'title_de',
    fr: 'title_fr',
    ru: 'title_ru',
    es: 'title_esp',
    pt: 'title_pt',
    it: 'title_it',
    br: 'title_pt',
    cz: 'title_cz',
    om: 'title_arb',
    tr: 'title_tk',
    hk: 'title_thai',
    in: 'title_hindi',
    ja: 'title_jap',
    // hk: 'title_hk',
  };

  return (
    <Carousel
      activeIndex={index}
      // direction={direction}
      onSelect={handleSelect}
    >
      {slides?.map((slide, index) => {
        if (slide) {
          return (
            <Carousel.Item className="container_slider tw-relative md:tw-max-h-lvh md:tw-min-h-lvh" key={index}>
              <Image
                src={slide}
                sizes="100vw"
                alt={slide}
                priority={index === 0}
                width={1300}
                height={725}
                className="tw-w-full tw-h-full tw-object-fill"
              />

              <Carousel.Caption className="tw-text-left md:tw-top-[42%] tw-top-[25%]">
                {index === 0 ? (
                  <h1 className="textShadow md:tw-text-6xl tw-text-2xl">
                    {restData[`${titleMapper[i18n.language]}${index + 1}`] || restData.title_latin1}
                  </h1>
                ) : (
                  <h2 className="textShadow md:tw-text-6xl tw-text-2xl">
                    {restData[`${titleMapper[i18n.language]}${index + 1}`] || restData.title_latin1}
                  </h2>
                )}
              </Carousel.Caption>
            </Carousel.Item>
          );
        }
      })}
    </Carousel>
  );
};

const Section1 = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation(lang as Locale);

  const [videoInfo, setVideoInfo] = useState<any>();
  const [shouldShowSlider, setShouldShowSlider] = useState<boolean>(false);
  const [carouselItems, setCarouselItems] = useState<any[]>([]);

  const videoTitleMapper = {
    en: 'title_latin',
    de: 'title_de',
    fr: 'title_fra',
    ru: 'title_ru',
    es: 'title_esp',
    pt: 'title_pt',
    it: 'title_it',
    //de: 'title_du',
    cz: 'title_cz',
    om: 'title_arb',
    tr: 'title_tk',
    hk: 'title_thai',
    in: 'title_hindi',
    ja: 'title_jap',
    // hk: 'title_hk',
  };

  const renderTitle = (videoInfo) => {
    return videoInfo[videoTitleMapper[i18n.language]] || videoInfo.title_latin;
  };

  const { isLoading, isSuccess, isError, refetch } = useQuery(
    'home_banner',
    () => fetch('https://api.iranjourney.com/api/pageconfig/home_banner_config_get').then((res) => res.json()),
    {
      onSuccess: (data: any[]) => {
        const { id, ...rest } = data[0];

        let sliderItems: string[] = [];

        for (let index = 1; index < 6; index++) {
          sliderItems.push(rest[`banner_1500_${index}`]);
        }

        if (rest.video_file && rest.video_file !== '' && typeof window !== 'undefined' && window.localStorage)
          localStorage.setItem('banner_video', moment().add(1, 'day').toString());

        setVideoInfo(rest);
        setCarouselItems([...sliderItems]);
      },
    }
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      let currentValue = localStorage.getItem('banner_video');

      if (currentValue) setShouldShowSlider(true);
      else setShouldShowSlider(false);

      if (currentValue && moment(currentValue).isBefore(moment())) {
        localStorage.removeItem('banner_video');
        setShouldShowSlider(false);
      }
    }
  }, []);

  if (isLoading) return <Loading />;

  if (isError) return <ApiFailed refetch={refetch} />;

  return (
    <div className="sec-1 slider-section md:tw-min-h-lvh">
      {videoInfo && videoInfo.video_file && !shouldShowSlider ? (
        <div className="mt-0 w-100">
          <div className="fs-3 text-center mb-3">{renderTitle(videoInfo)}</div>
          <video src={videoInfo.video_file} controls className="w-100" autoPlay={true}>
            Video not supported.
          </video>
        </div>
      ) : (
        <PageCarousel slides={carouselItems} restData={videoInfo} i18n={i18n} />
      )}
    </div>
  );
};

export default Section1;
