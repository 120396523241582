'use client';

import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import React, { Fragment, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Locale } from '../../../../i18n-config';
import Loading from '@/components/shared/Loading';
import ApiFailed from '@/components/shared/ApiFailed';
import Image from 'next/image';
import Link from 'next/link';
import TourCard from '@/components/shared/TourCard';

const styles = {
  font_size_13: { fontSize: '13px' },
  font_size_30: { fontSize: '30px' },
};

const Section2 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  const [topDeals, setTopDeals] = useState([]);

  const { isLoading, isSuccess, isError, data, refetch } = useQuery(
    'top_deals',
    () => fetch('https://api.iranjourney.com/api/pageconfig/top_deals_get').then((res) => res.json()),
    {
      onSuccess: (data) => {
        const { id, ...rest } = data[0];
        setTopDeals(rest);
      },
    }
  );

  if (isLoading) return <Loading />;

  if (isError) return <ApiFailed refetch={refetch} />;

  if (isSuccess && data && data.length > 0)
    return (
      <div className="sec-2 top-deals tw-my-8">
        <Container>
          <hr className="hr-text" data-content={t('key1')} />

          <Row className="justify-content-center g-3">
            {Object.keys(topDeals).map((item, index) => (
              <Col md={6} lg={4} xs={12} key={index} className="tw-flex">
                <TourCard
                  id={topDeals[item].id}
                  description={topDeals[item].overview}
                  duration={topDeals[item].duration}
                  image={topDeals[item].banner_thumbnail}
                  price={topDeals[item].price}
                  title={topDeals[item].title}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  else return null;
};

export default Section2;
