'use client';

import React, { useContext, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { CountryDropdown } from 'react-country-region-selector';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Contexts from '@/contexts';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../../i18n-config';
import Image from 'next/image';
import { getCode } from 'country-list';

const MyVerticallyCenteredModal = (props) => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Body className="pb-0">
        <div className="container mt-2 px-1 pt-2 pb-0">
          <div className="container">
            <hr />
            <p style={{ fontSize: '90px', color: '#521232' }} className="font-weight-bold text-center seorgesb">
              {t('THANK YOU')}
            </p>
            <br />
            <p style={{ fontSize: '30px', color: '#349fc9' }} className="text-center mt-2 pt-1">
              {t('FOR SUBSCRIBING')}
            </p>
            <hr />

            <p className="text-center px-5 font-weight-bold">
              {t(
                'We promise not to spam you, and to only send you emails, that make you smile,save your money, or teach you something new!'
              )}
            </p>
          </div>

          <div className="text-center py-0">
            <button
              onClick={props.onHide}
              style={{
                color: 'orange',
                background: 'white',
                fontSize: '19px',
              }}
              className="btn seorgesb px-3 mt-2"
            >
              {t('Ok ')}
            </button>
          </div>

          <br />
          <br />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const Subscription = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);
  const recaptchaRef = React.createRef();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [apiErrors, setApiErrors] = useState<any>();

  const data = useContext(Contexts.sharedDataContext);

  const selectCountry = (val) => {
    if (val === 'United States') {
      return 'us';
    } else if (val === 'United Kingdom') {
      return 'gb';
    } else if (val === 'Vietnam') {
      return 'vn';
    } else if (val === 'Cape Verde') {
      return 'cv';
    } else if (val === 'Czech Republic') {
      return 'cz';
    } else if (val === 'Greenland') {
      return 'gl';
    } else if (val === 'Macedonia, Republic of') {
      return 'mk';
    } else if (val === 'Swaziland') {
      return 'sz';
    } else if (val === 'Taiwan') {
      return 'tw';
    } else if (val === 'Holy See (Vatican City)') {
      return 'va';
    } else {
      return getCode(val).toLowerCase();
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    let normalizedData = data;

    normalizedData.country = selectCountry(data.country).toUpperCase();

    axios
      .post(`https://api.iranjourney.com/api/contact/subscribers/subscribe`, normalizedData)
      .then(() => {
        setModalShow(true);
      })
      .catch((err) => {
        if (typeof err.response.data == 'object') setApiErrors(err.response.data);
      });
  };

  return (
    <div className="sec-12">
      <Container className="py-5">
        <div className="subscription-page">
          <Image
            src={data.subscribe_img}
            width={'0'}
            height={'0'}
            sizes="100vw"
            className="w-100 tw-min-h-[500px] tw-object-cover"
            alt="Join our community"
          />
          <div className="overlay">
            <h2>{t('key6')}</h2>
            <p>
              {t('key7')}
              {t('key8')}
            </p>
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
              <Row className="g-3">
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Control placeholder={t('key9')} isInvalid={!!apiErrors?.name} {...register('name')} />
                    <Form.Control.Feedback type="invalid">
                      {apiErrors?.name && apiErrors?.name[0]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Form.Group>
                        <CountryDropdown
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          ref={ref}
                          classes={`form-control ${!!apiErrors?.country ? 'is-invalid' : ''}`}
                          whitelist={[
                            'AL',
                            'AR',
                            'AM',
                            'AU',
                            'AT',
                            'AZ',
                            'BH',
                            'BY',
                            'BE',
                            'BA',
                            'BR',
                            'BG',
                            'CA',
                            'CL',
                            'CN',
                            'CO',
                            'HR',
                            'CY',
                            'CZ',
                            'DK',
                            'EG',
                            'EE',
                            'FJ',
                            'FI',
                            'FR',
                            'GE',
                            'DE',
                            'GH',
                            'GR',
                            'HK',
                            'HU',
                            'IS',
                            'IN',
                            'ID',
                            'IQ',
                            'IE',
                            'IL',
                            'IT',
                            'JP',
                            'JO',
                            'KZ',
                            'KR',
                            'KW',
                            'KG',
                            'LV',
                            'LB',
                            'LI',
                            'LT',
                            'LU',
                            'MO',
                            'MK',
                            'MG',
                            'MY',
                            'MV',
                            'ML',
                            'MT',
                            'MX',
                            'MC',
                            'MN',
                            'MA',
                            'MM',
                            'NA',
                            'NP',
                            'NL',
                            'NZ',
                            'NO',
                            'OM',
                            'PE',
                            'PH',
                            'PL',
                            'PT',
                            'PR',
                            'QA',
                            'RO',
                            'RU',
                            'SA',
                            'RS',
                            'SG',
                            'SK',
                            'SI',
                            'ZA',
                            'ES',
                            'SE',
                            'CH',
                            'TW',
                            'TJ',
                            'TH',
                            'TN',
                            'TR',
                            'TM',
                            'UA',
                            'AE',
                            'GB',
                            'US',
                            'UZ',
                            'VN',
                          ]}
                          aria-label={t('key10')}
                        />
                        <Form.Control.Feedback type="invalid">
                          {apiErrors?.country && apiErrors?.country[0]}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  />
                </Col>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Control
                      placeholder={t('key11')}
                      type="email"
                      isInvalid={!!apiErrors?.email}
                      {...register('email')}
                    />
                    <Form.Control.Feedback type="invalid">
                      {apiErrors?.email && apiErrors?.email[0]}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <button type="submit">{t('key12')}</button>
                </Col>
              </Row>
            </Form>
            <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA} />

            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Subscription;
