'use client';

import { useTranslation } from '@/i18n/client';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { Locale } from '../../../../i18n-config';

const Section13 = ({ title }: { title?: string }) => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  return (
    <div className="sec-13 sm:tw-hidden">
      <Container>
        <Link href={`/${lang}/Tailor-made-Iran-tours`} className="tw-block">
          <Button className="seorge tw-bg-white text-timnak tw-text-3xl tw-rounded-lg tw-border-none tw-p-6 tw-w-full">
            {title ? title : t('Customize your own trip ')}
          </Button>
        </Link>
      </Container>
    </div>
  );
};

export default Section13;
