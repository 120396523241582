'use client';

import { useTranslation } from '@/i18n/client';
import Image from 'next/image';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Locale } from '../../../i18n-config';

interface ITourCard {
  id: number;
  image: string;
  duration: string | number;
  title: string;
  description: string;
  price: string;
  extraCation?: React.ReactNode;
  badges?: string[];
  shouldImageBePriority?: boolean;
}

const shorten = (text: string, max: number): string => {
  return text && text.length > max ? text.slice(0, max).split(' ').slice(0, -1).join(' ') : text;
};

const TourCard = ({
  id,
  description,
  duration,
  image,
  price,
  title,
  extraCation,
  badges,
  shouldImageBePriority = false,
}: ITourCard) => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  return (
    <Card className="tourCardComponent tw-flex-1 tw-min-h-[450px]">
      <Card.Header className="tw-relative tw-p-0">
        <Image
          src={`${!image.startsWith('http') ? 'https://api.iranjourney.com' + image : image}`}
          alt={title}
          title={title}
          width="0"
          height="0"
          sizes="(max-width: 768px) 50vw, (max-width: 1200px) 100vw, 33vw"
          className="tw-w-full tw-h-auto tw-rounded-t-md tw-object-cover"
          priority={shouldImageBePriority}
        />
      </Card.Header>
      <Card.Body className="py-2 tw-flex tw-flex-col tw-min-h-[270px]">
        <Card.Title>
          <div className="d-flex align-items-center mb-3" style={{ gap: 20 }}>
            <div className="pe-3 tw-text-center" style={{ borderRight: '3px solid black' }}>
              <span className="mb-0 tw-block fs-2">{duration} </span>
              <p className="mb-0">{t('Days ')}</p>
            </div>
            <h2 className="fs-5 tw-mb-0" style={{ overflowWrap: 'anywhere' }}>
              {title}
            </h2>
          </div>
        </Card.Title>
        <Card.Text className="seorge tw-mb-4 tw-mt-auto tw-text-left">{shorten(description, 70) + ' ...'}</Card.Text>
        <div className="mt-auto tw-flex tw-items-end tw-justify-between tw-mb-3">
          {badges &&
            badges.length > 0 &&
            badges.map((badge) => (
              <span
                key={badge}
                className="tw-inline-flex tw-items-center tw-rounded-md tw-bg-purple-50 tw-px-2 tw-py-1 tw-text-xs tw-font-medium tw-text-purple-600 tw-ring-1 tw-ring-inset tw-ring-purple-500/10"
              >
                {badge}
              </span>
            ))}
          <div className="price tw-ms-auto">
            <b className="d-inline-block me-2">{t('From ')}</b>
            <span className="d-inline-block tw-text-[#521231] tw-font-bold">${price}</span>
          </div>
        </div>
        <div className="tw-flex tw-justify-evenly tw-items-center tw-gap-3">
          {typeof extraCation !== 'undefined' && <div className="tw-basis-1/2">{extraCation}</div>}
          <Link
            href={`/${lang}/tours/${id}`}
            className={`${
              !extraCation ? 'tw-basis-2/2' : 'tw-basis-1/2'
            } btn tw-w-full tw-text-white tw-bg-[#521231] hover:tw-text-white hover:tw-bg-[#521231]`}
          >
            {t('View Details ')}
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default TourCard;
