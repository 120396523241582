'use client';

import { useParams } from 'next/navigation';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Locale } from '../../../../i18n-config';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useTranslation } from '@/i18n/client';
import Loading from '@/components/shared/Loading';
import ApiFailed from '@/components/shared/ApiFailed';
import TourCard from '@/components/shared/TourCard';

const Section3 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  const isMobile = useMediaQuery('only screen and (max-width : 768px)');

  const {
    isLoading,
    isError,
    isSuccess,
    refetch,
    data: mostPopularTours,
  } = useQuery('mostPopularTours', () =>
    fetch('https://api.iranjourney.com/api/tour/get_suggested_tours').then((res) => res.json())
  );

  if (isLoading) return <Loading />;

  if (isError) return <ApiFailed refetch={refetch} />;

  if (isSuccess && mostPopularTours && mostPopularTours.length > 0)
    return (
      <div className="sec-3 most-popular-tours">
        <Container className="mt-5">
          <hr className="hr-text" data-content={t('Most Popular Iran Tours')} />

          <Row className="g-3">
            {(isMobile ? mostPopularTours.slice(0, 4) : mostPopularTours).map((mostPopularTour, index) => (
              <Col md={6} lg={3} xs={12} key={index} className="tw-flex">
                <TourCard
                  id={mostPopularTour.id}
                  description={mostPopularTour.overview}
                  duration={mostPopularTour.duration}
                  image={mostPopularTour.banner_thumbnail}
                  price={mostPopularTour.price}
                  title={mostPopularTour.title}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  else return null;
};

export default Section3;
