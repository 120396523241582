'use client';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Image from 'next/image';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../../i18n-config';

const Section5 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  return (
    <div className="sec-5 travel-diary">
      <Container>
        <Row className="g-0">
          <Col md={6}>
            <Image
              src={'/images/Traveldiary.jpg'}
              width={546}
              height={187}
              sizes="100vw"
              className="tw-w-full tw-h-full"
              alt="A travel diary of New Zealand guests in Iran"
            />
          </Col>
          <Col md={6} className="p-3 bg-white tw-flex tw-flex-col">
            <h3 className="md:tw-text-5xl tw-text-2xl">
              A travel diary of <br />
              New Zealander guests <br />
              in Iran
            </h3>
            <Link
              href={`/${lang}/new-zealanders-travel-diary`}
              className="tw-mt-auto btn tw-w-full tw-text-white tw-bg-[#521231] hover:tw-text-white hover:tw-bg-[#521231]"
            >
              {t('Read full text')}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Section5;
