'use client';

import ApiFailed from '@/components/shared/ApiFailed';
import Loading from '@/components/shared/Loading';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Locale } from '../../../../i18n-config';
import { useTranslation } from '@/i18n/client';
import Image from 'next/image';

const Section4 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  const [GroupTours, setGroupTours] = useState<any>();

  const { isLoading, isSuccess, isError, data, refetch } = useQuery(
    'group_tours',
    () => fetch('https://api.iranjourney.com/api/pageconfig/group_tour_get').then((res) => res.json()),
    {
      onSuccess: (data) => {
        const { id, ...rest } = data[0];

        setGroupTours(rest);
      },
    }
  );

  if (isLoading) return <Loading />;

  if (isError) return <ApiFailed refetch={refetch} />;

  if (isSuccess && data && data.length > 0)
    return (
      <div className="sec-4 group-tours">
        <Container className="mt-5">
          <hr className="hr-text" data-content={t('key2')} />
        </Container>
        <React.Fragment>
          {GroupTours &&
            Object.keys(GroupTours.tour_id1).length > 0 &&
            GroupTours.tour_id1.title &&
            GroupTours.tour_id1.cities &&
            GroupTours.tour_id1.cities.length > 0 && (
              <div className="group-tour-item">
                <div className="banner">
                  <Container fluid className="tw-relative tw-py-6">
                    <Image
                      style={{ objectFit: 'cover' }}
                      src={`${GroupTours.cross_banner_1}`}
                      alt={GroupTours.tour_id1.title}
                      fill
                      sizes="100vw"
                    />
                    <div className="overlay">
                      <div className="duration-title">
                        <div className="duration">
                          <span className="fontMontserrat">{GroupTours.tour_id1.duration}</span>
                          <sup className="fontMontserrat">{t('Days ')}</sup>
                        </div>
                        <div className="title fontMontserrat">{GroupTours.tour_id1.title}</div>
                      </div>
                      <div className="description">
                        <p>{GroupTours.tour_id1.description}</p>
                      </div>
                      <div className="size-price-link">
                        <div className="size-price">
                          <div className="size">
                            <span className="fontMontserrat">{t('Group size')}</span>
                            <b className="fontMontserrat">{GroupTours.tour_id1.group_size}</b>
                            <span className="fontMontserrat">{t('Persons')}</span>
                          </div>
                          <div className="price">
                            <b>${GroupTours.tour_id1.price}</b>
                            <div>
                              <p>{t('Per')}</p>
                              <p>{t('Person')}</p>
                            </div>
                          </div>
                          <div className="link">
                            <Link href={`/${lang}/tours/${GroupTours.tour_id1.id}`}>{t('Inquire now')}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className="cities">
                  <ul className="list-unstyled p-0 m-0">
                    {GroupTours.tour_id1.cities
                      .filter((item) => item !== null)
                      .map((city) => (
                        <li key={city}>{city}</li>
                      ))}
                  </ul>
                </div>
              </div>
            )}

          {GroupTours &&
            Object.keys(GroupTours.tour_id2).length > 0 &&
            GroupTours.tour_id2.title &&
            GroupTours.tour_id2.cities &&
            GroupTours.tour_id2.cities.length > 0 && (
              <div className="group-tour-item">
                <div className="banner">
                  <Container fluid className="tw-relative tw-py-6">
                    <Image
                      style={{ objectFit: 'cover' }}
                      src={`${GroupTours.cross_banner_2}`}
                      alt={GroupTours.tour_id2.title}
                      fill
                      sizes="100vw"
                    />
                    <div className="overlay">
                      <div className="duration-title">
                        <div className="duration">
                          <span>{GroupTours.tour_id2.duration}</span>
                          <sup>{t('Days ')}</sup>
                        </div>
                        <div className="title">{GroupTours.tour_id2.title}</div>
                      </div>
                      <div className="description">
                        <p>{GroupTours.tour_id2.description}</p>
                      </div>
                      <div className="size-price-link">
                        <div className="size-price">
                          <div className="size">
                            <span>{t('Group size')}</span>
                            <b>{GroupTours.tour_id2.group_size}</b>
                            <span>{t('Persons')}</span>
                          </div>
                          <div className="price">
                            <b>${GroupTours.tour_id2.price}</b>
                            <div>
                              <p>{t('Per')}</p>
                              <p>{t('Person')}</p>
                            </div>
                          </div>
                        </div>
                        <div className="link">
                          <Link href={`/${lang}/tours/${GroupTours.tour_id2.id}`}>{t('Inquire now')}</Link>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className="cities">
                  <ul className="list-unstyled p-0 m-0">
                    {GroupTours.tour_id2.cities
                      .filter((item) => item !== null)
                      .map((city) => (
                        <li key={city}>{city}</li>
                      ))}
                  </ul>
                </div>
              </div>
            )}

          {GroupTours &&
            Object.keys(GroupTours.tour_id3).length > 0 &&
            GroupTours.tour_id3.title &&
            GroupTours.tour_id3.cities &&
            GroupTours.tour_id3.cities.length > 0 && (
              <div className="group-tour-item">
                <div className="banner">
                  <Container fluid className="tw-relative tw-py-6">
                    <Image
                      style={{ objectFit: 'cover' }}
                      src={`${GroupTours.cross_banner_3}`}
                      alt={GroupTours.tour_id3.title}
                      fill
                      sizes="100vw"
                    />
                    <div className="overlay">
                      <div className="duration-title">
                        <div className="duration">
                          <span>{GroupTours.tour_id3.duration}</span>
                          <sup>{t('Days ')}</sup>
                        </div>
                        <div className="title">{GroupTours.tour_id3.title}</div>
                      </div>
                      <div className="description">
                        <p>{GroupTours.tour_id3.description}</p>
                      </div>
                      <div className="size-price-link">
                        <div className="size-price">
                          <div className="size">
                            <span>{t('Group size')}</span>
                            <b>{GroupTours.tour_id3.group_size}</b>
                            <span>{t('Persons')}</span>
                          </div>
                          <div className="price">
                            <b>${GroupTours.tour_id3.price}</b>
                            <div>
                              <p>{t('Per')}</p>
                              <p>{t('Person')}</p>
                            </div>
                          </div>
                        </div>
                        <div className="link">
                          <Link href={`/${lang}/tours/${GroupTours.tour_id3.id}`}>{t('Inquire now')}</Link>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className="cities">
                  <ul className="list-unstyled p-0 m-0">
                    {GroupTours.tour_id3.cities
                      .filter((item) => item !== null)
                      .map((city) => (
                        <li key={city}>{city}</li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
        </React.Fragment>
      </div>
    );
  else return null;
};

export default Section4;
