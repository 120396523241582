'use client';

import ApiFailed from '@/components/shared/ApiFailed';
import Loading from '@/components/shared/Loading';
import { useTranslation } from '@/i18n/client';
import Image from 'next/image';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Locale } from '../../../../i18n-config';

const Section14 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  const {
    isLoading,
    isError,
    isSuccess,
    refetch,
    data: suggested_articles,
  } = useQuery('suggested_articles', () =>
    fetch('https://api.iranjourney.com/api/post/get_suggested_articles').then((res) => res.json())
  );

  if (isLoading) return <Loading />;

  if (isError) return <ApiFailed refetch={refetch} />;

  if (isSuccess && suggested_articles.length > 0)
    return (
      <div className="sec-14 suggested-articles">
        <Container className="mb-4">
          <hr className="hr-text" data-content={t('Latest Articles')} />

          <Row className="g-3">
            {suggested_articles.slice(0, 4).map((article, index) => (
              <Col md={6} lg={3} xs={12} key={index} className="tw-flex">
                <Link href={`/articles-and-news/${article.id}`} className="text-decoration-none tw-flex tw-flex-1">
                  <Card className="tw-shadow-md tw-flex-1">
                    <Card.Header className="tw-p-0">
                      <Image
                        className="tw-object-cover tw-w-full tw-h-[200px]"
                        alt={article.title.substring(0, 50) + '...'}
                        src={'https://api.iranjourney.com' + article.picture_thumbnail}
                        width={0}
                        height={0}
                        sizes="100vw"
                      />
                    </Card.Header>
                    <Card.Body>
                      <Card.Title
                        style={{
                          fontWeight: 'bold',
                          color: 'black',
                          fontSize: '19px',
                        }}
                      >
                        {article.title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  else return null;
};

export default Section14;
