'use client';

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import parser from 'html-react-parser';
import Link from 'next/link';
import Loading from '@/components/shared/Loading';
import ApiFailed from '@/components/shared/ApiFailed';
import { useParams } from 'next/navigation';
import { Locale } from '../../../../i18n-config';
import { useTranslation } from '@/i18n/client';
import { FaCalendar, FaUser } from 'react-icons/fa';

const Section10 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  const { isLoading, isError, isSuccess, refetch, data } = useQuery('travel-reviews', () =>
    fetch('https://api.iranjourney.com/api/pageconfig/home_page_guest_book_list').then((res) => res.json())
  );

  if (isLoading) return <Loading />;

  if (isError) return <ApiFailed refetch={refetch} />;

  if (isSuccess && data && data.length > 0)
    return (
      <div className="sec-10">
        <Container>
          <hr className="hr-text" data-content={t('Travel Reviews ')} />

          <p style={{ fontSize: '22px' }} className="text-center px-1">
            {t('What our clients are saying about their Iran Journey')}
          </p>

          <Row className="g-3">
            {data
              .sort((a, b) => b.id - a.id)
              .map((item) => (
                <Col md={6} key={item.id} className="tw-flex">
                  <Link href={`${lang}/guest-books`} className="text-decoration-none rounded-2 tw-flex tw-flex-1">
                    <div
                      className="md:tw-p-5 tw-p-3 tw-flex-1"
                      style={{ backgroundColor: 'white', borderRadius: '10px' }}
                    >
                      <p className="text-center seorgesb py-1 tw-text-3xl tw-text-gray-800">&quot;{item.title}&quot;</p>

                      <div className="tw-flex tw-items-center tw-justify-between tw-mb-5">
                        <p className="text-left seorgesb tw-text-xl tw-text-gray-800">
                          <FaUser size={22} color="#521231" className="me-2" />

                          {item.country}
                        </p>

                        <p className="text-right  seorgesb tw-text-xl tw-text-gray-800">
                          <FaCalendar size={22} color="#521231" className="me-2" />

                          {item.travel_date}
                        </p>
                      </div>

                      <p className="text-dark">{parser(item.text).toString().substring(0, 300) + '...'} </p>

                      <span className="text-primary">Read more</span>
                    </div>
                  </Link>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    );
  else return null;
};

export default Section10;
