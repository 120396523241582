'use client';

import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import Contexts from '@/contexts';
import Link from 'next/link';
import Image from 'next/image';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../../i18n-config';

const Section6 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  const data = useContext(Contexts.sharedDataContext);

  return (
    <div className="sec-6">
      <Container>
        <hr className="hr-text" data-content={t('key3')} />

        <Row className="g-4">
          <Col md={3} sm={6} xs={12} className="tw-flex">
            <div className="tw-bg-white tw-flex-1">
              <Link href={`${lang}/travel-cost-estimation`} className="text-decoration-none tw-relative tw-block">
                <Image
                  width={'0'}
                  height={'0'}
                  sizes="100vw"
                  src={data.travel_cost_image}
                  alt={data.travel_cost_title.substring(0, 100) + '...'}
                  className="tw-w-full tw-h-auto"
                />
                <div className="px-2 py-3 text-center">
                  <p className="mb-0" style={{ color: '#521232' }}>
                    {data.travel_cost_title}
                  </p>
                </div>
              </Link>
            </div>
          </Col>
          <Col md={3} sm={6} xs={12} className="tw-flex">
            <div className="tw-bg-white tw-flex-1">
              <Link href={`${lang}/visa-for-visiting-iran`} className="text-decoration-none tw-relative tw-block">
                <Image
                  width={'0'}
                  height={'0'}
                  sizes="100vw"
                  src={data.iran_vis_image}
                  alt={data.iran_visa_title.substring(0, 100) + '...'}
                  className="tw-w-full tw-h-auto"
                />
                <div className="px-2 py-3 text-center">
                  <p className="mb-0" style={{ color: '#521232' }}>
                    {data.iran_visa_title}
                  </p>
                </div>
              </Link>
            </div>
          </Col>
          <Col md={3} sm={6} xs={12} className="tw-flex">
            <div className="tw-bg-white tw-flex-1">
              <Link
                href={`${lang}/iran-visa-for-british-american-canadian-passport-holders`}
                className="text-decoration-none tw-relative"
              >
                <Image
                  width={'0'}
                  height={'0'}
                  sizes="100vw"
                  src={data.special_visa_image}
                  alt={data.special_visa_title.substring(0, 100) + '...'}
                  className="tw-w-full tw-h-auto"
                />
                <div className="px-2 py-3 text-center">
                  <p className="mb-0" style={{ color: '#521232' }}>
                    {data.special_visa_title}
                  </p>
                </div>
              </Link>
            </div>
          </Col>
          <Col md={3} sm={6} xs={12} className="tw-flex">
            <div className="tw-bg-white tw-flex-1">
              <Link href={`${lang}/tailor-made-iran-tours`} className="text-decoration-none tw-relative tw-block">
                <Image
                  width={'0'}
                  height={'0'}
                  sizes="100vw"
                  src={data.customize_my_trip_image}
                  alt={data.customize_my_trip_title.substring(0, 100) + '...'}
                  className="tw-w-full tw-h-auto"
                />
                <div className="px-2 py-3 text-center">
                  <p className="mb-0" style={{ color: '#521232' }}>
                    {data.customize_my_trip_title}
                  </p>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Section6;
