'use client';

import React from 'react';
import { useQuery } from 'react-query';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../../../i18n-config';
import Loading from '@/components/shared/Loading';
import ApiFailed from '@/components/shared/ApiFailed';
import Image from 'next/image';
import { useMediaQuery } from '@uidotdev/usehooks';

const Section7 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)');

  const { isLoading, isSuccess, isError, data, refetch } = useQuery('up_coming_events', () =>
    fetch('https://api.iranjourney.com/api/pageconfig/up_coming_get').then((res) => res.json())
  );

  if (isLoading) return <Loading />;

  if (isError) return <ApiFailed refetch={refetch} />;

  if (isSuccess && data && data.length > 0 && !isSmallDevice)
    return (
      <div className="sec-7 upcoming-events">
        <Container>
          <hr className="hr-text" data-content={t('Upcoming events')} />
          <Row className="g-3">
            {data.map((item) => (
              <Col xs={12} key={item.id}>
                <Card className="flex-sm-row flex-column">
                  <Image
                    className="card-img-top tw-w-[200px] tw-h-[200px] tw-object-cover"
                    alt={item.title}
                    src={item.image}
                    width={0}
                    height={0}
                    sizes="only screen and (min-width : 1201px) 20vw 10vw"
                  />

                  <Card.Body className="py-2 d-flex flex-column">
                    <h4>{item.title}</h4>
                    <p className="p-0 text-left">{item.description}</p>
                    <ul className="list-unstyled p-0 mt-auto mb-0">
                      {item.location && (
                        <li>
                          <b className="mr-2">{t('Location')}: </b>
                          {item.location}
                        </li>
                      )}
                      {item.direction && (
                        <li>
                          <b className="mr-2">{t('Direction')}: </b>
                          {item.direction}
                        </li>
                      )}
                      {item.date && (
                        <li>
                          <b className="me-2">{t('Date')}: </b>
                          {item.date}
                        </li>
                      )}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    );
  else return null;
};

export default Section7;
