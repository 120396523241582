'use client';

import Contexts from '@/contexts';
import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Locale } from '../../../../i18n-config';

const Section11 = () => {
  const { lang } = useParams();
  const { t } = useTranslation(lang as Locale);

  const data = useContext(Contexts.sharedDataContext);

  return (
    <div className="sec-11">
      <p className="text-center mt-5 map_title tw-text-4xl">
        {t('key4')} {t('key5')}
      </p>

      <Container fluid>
        <Row className="tw-py-6 tw-bg-[#F6F6F6]">
          <Col>
            <p className="count_number">{new Intl.NumberFormat().format(data.records_application)}</p>
            <p className="count_title">{t('Applications')}</p>
          </Col>

          <Col>
            <p className="count_number">{new Intl.NumberFormat().format(data.records_passenger)}</p>
            <p className="count_title">{t('Passengers')}</p>
          </Col>

          <Col>
            <p className="count_number">{new Intl.NumberFormat().format(data.records_nationality)}</p>
            <p className="count_title">{t('Nationalities')}</p>
          </Col>

          <Col>
            <p className="count_number">{new Intl.NumberFormat().format(data.records_day_tour)}</p>
            <p className="count_title">{t('Day-Tour')}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Section11;
